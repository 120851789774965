/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2019-11-18 18:18:21
 * @Last Modified by: mengying.yao
 * @Last Modified time: 2019-11-18 18:31:42
 * @Description: Description
 * @Route: Route
 */

<template>
  <div class="statistics_export">
    <div class="form_item">
      <span class="label">
        选择日期</span>

      <el-date-picker
        v-model="data.time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期" />
    </div>
    <div class="form_item">
      <span class="label">
        选择导出字段</span>

      <el-radio-group v-model="data.type">
        <el-radio :label="1">
          观看人数
        </el-radio>
        <el-radio :label="2">
          观看人次
        </el-radio>
        <el-radio :label="3">
          用户提问数
        </el-radio>
      </el-radio-group>
    </div>
    <el-button class="btn_export"
               type="primary">
      导出
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        time: [],
        type: 1,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.statistics_export {
  padding: 30px;
}

.label {
  font-size: 14px;
  margin-right: 20px;
  width: 120px;
  text-align: right;
}

.form_item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.btn_export {
  margin-top: 60px;
  margin-left: 220px;
}

</style>
